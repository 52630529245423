import React, { useEffect, useState } from "react"
import { Controller } from "react-hook-form"
import {
  NextCreateableSelect,
  NextSelect,
} from "../../../../components/molecules/select/next-select"
import TagInput from "../../../../components/molecules/tag-input"
import TextArea from "../../../../components/molecules/textarea"
import { Option } from "../../../../types/shared"
import { NestedForm } from "../../../../utils/nested-form"
import useOrganizeData from "./use-organize-data"
import CategoryController from "../../../../services/category"
import InputField from "../../../../components/molecules/input"

export type OrganizeFormType = {
  type: Option | null
  collection: Option | null
  tags: string[] | null
  desc: string | null
}

type Props = {
  form: NestedForm<OrganizeFormType>
}

const OrganizeForm = ({ form }: Props) => {
  const { control, path, setValue, register } = form
  // const { productTypeOptions, collectionOptions } = useOrganizeData()

  const [categories, setCategories] = useState([])

  const getCategories = async () => {
    const result = await CategoryController.getCategories()
    setCategories(
      result?.data?.map((item: any) => {
        return {
          ...item,
          label: item.title,
        }
      })
    )
  }

  useEffect(() => {
    getCategories()
  }, [])

  // const typeOptions = productTypeOptions

  // const onCreateOption = (value: string) => {
  //   typeOptions.push({ label: value, value })
  //   setValue(
  //     path("type"),
  //     { label: value, value },
  //     {
  //       shouldDirty: true,
  //     }
  //   )
  // }

  return (
    <div>
      <div className="grid grid-cols-2 gap-x-large mb-large">
        <div className="col-span-1">
          <Controller
            name={path("desc")}
            control={control}
            render={({ field: { value, onChange } }: any) => {
              return (
                <TextArea
                  label="Desctoption"
                  onChange={onChange}
                  value={value}
                  placeholder="Enter desciption"
                />
              )
            }}
          />
        </div>

        <div className="col-span-1 flex flex-col gap-4">
          <InputField
            className="grid-col-1"
            label="Type"
            placeholder="Enter type"
            {...register(path("type"))}
          />
          {/* <Controller
            name={}
            control={control}
            render={({ field: { value, onChange } }) => {
              return (
                <NextCreateableSelect
                  label="Type"
                  onChange={onChange}
                  options={productTypeOptions}
                  value={value || null}
                  placeholder="Choose a type"
                  onCreateOption={onCreateOption}
                  isClearable
                />
              )
            }}
          /> */}
          <Controller
            name={path("collection")}
            control={control}
            render={({ field: { value, onChange } }) => {
              return (
                <NextSelect
                  label="Category"
                  onChange={onChange}
                  options={categories}
                  value={value}
                  placeholder="Choose a category"
                  isClearable
                />
              )
            }}
          />
          <Controller
            control={control}
            name={path("tags")}
            render={({ field: { value, onChange } }) => {
              return <TagInput onChange={onChange} values={value || []} />
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default OrganizeForm
